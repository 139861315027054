const sdkUrlList = [
    {
        BASEURL: 'http://192.168.1.26:8081/#/sdk/main',
    },
    {
        BASEURL: 'https://sdkh5.7li.cn/#/sdk/main',
    },
];

let sdkUrlArr;

sdkUrlArr = sdkUrlList[1]
let sdkUrl = sdkUrlArr.BASEURL
export default sdkUrl

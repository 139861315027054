<template>
    <div class="main" v-show="initFinished">
        <div class="title">
            <van-icon name="arrow-left" size="20" @click="back"/>
            <span style="margin-left: 10px;">{{gameName}}</span>
        </div>

        <div style="margin: 50px 15px 0;box-sizing: border-box;padding: 0" >
            <video v-if="!!videoUrl" :src="videoUrl" style="width: 100%; object-fit:cover;border-radius: 5px;" :poster="videoImageUrl"  :controls="true" :autoplay="true"></video>
        </div>
        <div class="game-info" :style="!videoUrl ? 'margin-top: 50px' : ''">
            <img :src="gameImgUrl" style="width: 80px;height: 80px;">
            <div class="game-text">
                <span style=" font-size: 16px;font-weight: bold;">{{gameName}}</span>
                <div class="tags">
                    <span v-for="item in tagList">{{item}}</span>
                </div>
                <div v-if="!!gameSize">
                    <span class="game-size-view" >{{gameSize}}</span>
                </div>
            </div>
        </div>

        <div v-if="showAppointmentView" style="text-align: left;font-weight: bold;font-size: 18px;margin: 20px 20px 15px">预约有礼</div>
        <div v-if="showAppointmentView" class="game-desc" style="display: flex;flex-direction: column;font-size: 14px;">
            <span>即日起，在本页面成功预约武魂觉醒极速服的玩家，开服后创建角色进入游戏即可免费领取：SSR-神骨龙</span>
            <span style="margin-top: 10px;">领取方式：进入武魂觉醒极速服游戏 / 打开游戏内七里浮窗 / 礼包领取 / 选择角色 / 预约专属礼包 / 领取</span>
            <span style="margin-top: 10px;">领取后复制兑换码，在游戏中兑换即可</span>
            <span style="margin-top: 10px;color: #f66a6a">领取时间：游戏开服后7个工作日内，过期后无法申请，请及时领取奖励</span>

        <van-button block color="#1ABC9C" :disabled="appointmentStatus === '2'" round style="font-size: 16px;font-weight: bold;margin-top: 30px;" @click="appointmentGame">
                {{appointmentStatus === '2' ? '已预约' : '立即预约'}}
            </van-button>
        </div>

        <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 20px 20px 15px">游戏简介</div>
        <div class="game-desc">
            {{gameDesc}}
        </div>

        <div style="padding: 10px 20px;bottom: 20px;left: 0;right: 0"  v-if="showButton">
            <van-checkbox style="margin-bottom: 20px;" v-model="openSpeed" icon-size="15px" checked-color="#ef2929" v-if="false">
                <span style="color: #e05d21;font-size: 13px;">进入加速版</span>
            </van-checkbox>
            <van-button block color="#e55825"  style="font-size: 16px;font-weight: bold;" @click="play">开始游戏</van-button>
        </div>

        <div style="padding: 10px 20px" v-if="android && !showAppointmentView">

            <van-button v-if="install === '1'" block color="#e05d21"  style="font-size: 14px;font-weight: bold;" @click="openGame">已安装，打开游戏</van-button>

            <div v-else>
                <van-button block round color="#e05d21" :loading="downloading" loading-text="请在通知栏查看下载进度" style="font-size: 16px;font-weight: bold;" @click="downloadGame">
                    下载游戏
                </van-button>
                <div v-if="appVersion >= 200" style="color: #888888;text-align: left;margin-top: 15px;font-size: 12px;">温馨提示: 下载游戏需要存储权限，请在弹出的授权窗口选择同意，否则将无法正常下载游戏。</div>
            </div>

        </div>

        <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 20px 20px 15px">开服信息</div>
        <div class="game-desc">
            <div>
                <span style="padding: 2px 8px;background-color: #ffab00;border-radius: 3px;color: #ffffff">最新开服</span>
                <div style="color:#444444;margin: 15px 15px;font-weight: bold">{{lastServerName || '暂无'}} <span style="margin-left: 20px">{{lastOpenTime}}</span></div>
            </div>
            <div>
                <span style="padding: 2px 8px;background-color: #ffab00;border-radius: 3px;color: #ffffff">即将开服</span>
                <div style="color:#444444;margin: 15px 15px 0;font-weight: bold">{{nextServerName || '暂无'}} <span style="margin-left: 20px">{{nextOpenTime}}</span></div>
            </div>
        </div>

        <div v-if="hasGift === '1' && appVersion >= 200">
            <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 20px 20px 15px">游戏礼包</div>
            <div class="game-desc" @click="goGiftDetail">
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <span style="font-size: 14px;">游戏专属福利礼包</span>
                    <span style="color: #888888">去查看<van-icon name="arrow" /></span>
                </div>
            </div>
        </div>


        <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 20px 20px 15px">游戏攻略</div>
        <div @click="goDetail(item.content_id,item.content_name)" v-for="item in glList" style="padding: 10px 15px;background-color: #FFFFFF;margin: 0 18px 5px;">
            <div style="display: flex;align-items: center">
                <img :src="item.headerIcon" style="width: 40px;height: 40px;border-radius: 100%; object-fit:cover;">
                <div style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-start; margin-left: 15px;">
                    <span style="color: #222222;font-size: 15px;">{{item.nickName}}</span>
                    <span style="color: #AAAAAA;font-size: 13px;white-space: pre">{{item.timeText}}    {{item.see_num}}浏览</span>
                </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between">
                <div style="text-align: left">
                    <h5>{{item.content_name}}</h5>
                    <span class="no-feed" style="font-size: 12px;color: #888888">{{item.content_des}}</span>
                </div>
                <img v-if="!!item.firstImg" style="width: 120px;height: 65px; object-fit:cover;"  :src="item.firstImg">
            </div>
        </div>
        <div v-if="glList.length == 0" style="text-align: center;padding: 20px 0 20px;font-size: 14px;color: #888888;">
            暂时还没有游戏攻略~
        </div>
        <div v-if="glList.length >= 3" style="text-align: center;padding: 20px 0 20px;font-size: 14px;color: #888888;">
            更多攻略请在攻略页面查看~
        </div>

        <div style="text-align: left;font-weight: bold;font-size: 18px;margin: 10px 20px 15px">玩家反馈</div>
        <div style="padding: 5px 20px;display: flex;justify-content: space-between;margin-bottom: 30px;">
            <van-field v-model="input" placeholder="你对该游戏的建议和意见" style="width: 80%"></van-field>
            <van-button type="info" @click="send">发布</van-button>
        </div>

    </div>
</template>

<script>
    import { gameDetail,getContentByGameId,fankui,commRequest } from "@/api/request";
    import {Toast, Dialog} from 'vant'

    export default {
        name: "app-game-detail",
        data() {
            return {
                gameId: 0,
                gameName: '',
                gameImgUrl: '',
                tagList: [],
                gameDesc: '',
                videoUrl: null,
                videoImageUrl: '',
                downLoadUrl: '',
                canPlay: false,
                showButton: false,
                login: '',
                u: '',
                p: '',
                glList: [],
                input: '',
                android: false,
                from: '',
                channelId: 1,
                dyChannel: '',
                openSpeed: false,
                gameSize: '',
                lastServerName: '',
                nextServerName: '',
                lastOpenTime: '',
                nextOpenTime: '',
                install: '0',
                hasGift: '',
                downloading: false,
                appVersion: 0,
                appointmentStatus: 0,
                showAppointmentView: false,
                initFinished: false,
            }
        },
        mounted() {
            this.isAndroid();
            this.initPage();
            this.getGameDetail();
            this.getContent();
        },
        methods: {
            initPage() {
                this.from = this.$route.query.from;
                //localStorage.setItem('from', this.from);
                let metaTag = document.getElementById('metaTag');
                if (this.from === 'qiliApp') {
                    metaTag.content = 'width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no,minimum-scale=1.0,viewport-fit=cover';
                }
                this.gameId = this.$route.query.id;
                this.login = this.$route.query.login;
                if (this.from === 'qiliApp' && this.login === '1') {
                    this.u = this.$route.query.u;
                    this.p = this.$route.query.p.replace(' ', '+');
                    localStorage.setItem('username', this.u);
                    localStorage.setItem('password', this.p);
                }

                if (!!this.$route.query.cid) {
                    this.channelId = this.$route.query.cid;
                }

                if (!!this.$route.query.dyChannel) {
                    this.dyChannel = this.$route.query.dyChannel;
                }

                if (!!this.$route.query.install) {
                    this.install = this.$route.query.install;
                }

                if (!!this.$route.query.appVersion) {
                    this.appVersion = this.$route.query.appVersion;
                    //Toast('appVersion: ' + this.appVersion);
                }
            },
            downloadGame() {
                if (!this.downLoadUrl) {
                    Toast('游戏正在分包，请稍后重新打开页面再试');
                    return ;
                }

                this.downloading = true;
                if (this.from === 'qiliApp') {
                    uni.postMessage({
                        data: {
                            action: 'androidDownload',
                            msg: {
                                downLoadUrl: this.downLoadUrl,
                                gameName: this.gameName
                            }
                        }
                    });
                    uni.postMessage({
                        data: {
                            action: 'dwonload',
                            msg: this.downLoadUrl
                        }
                    });
                }
            },
            openGame() {
                if (this.from === 'qiliApp') {
                    uni.postMessage({
                        data: {
                            action: 'openGame',
                            msg: ''
                        }
                    });
                }
            },
            isAndroid(){
                let u = navigator.userAgent;
                if(u.indexOf('Android') > -1 || u.indexOf('Linux') > -1){
                    this.android = true;
                    console.log('Android device');
                }
            },
            getContent() {
                let params = {
                    pageNo: 1,
                    pageSize: 3,
                    params: {
                        gameId: this.gameId
                    }
                }
                getContentByGameId(params).then(res => {
                    if (res.data.success) {
                        console.log(res.data.data);
                        let list = res.data.data.list;
                        for (let i = 0; i < list.length; i++) {
                            this.glList.push(list[i]);
                        }
                    }
                    else {
                        Toast.fail(res.data.error.message);
                    }
                })
            },
            back() {
                uni.postMessage({
                    data: {
                        action: 'back',
                        msg: ''
                    }
                });
            },
            play() {
              if (this.gameId === 0) {
                  return ;
              }
              if (this.login !== '1') {
                  Dialog.alert({
                      title : '温馨提示',
                      message : '请先在七里APP登录成功后再继续试玩',
                      messageAlign: 'center'
                  })
                  return ;
              }

              if (this.gameId === '206') {
                  let os = this.openSpeed ? "1" : "0";
                  window.location.href = `https://ts.7li.cn/#/sq/${this.channelId}/206?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}&os=${os}`;
                  //window.location.href = `http://192.168.0.200:8081/#/sq/1/206?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}&os=${os}`;
                  return ;
              }

                if (this.gameId === '397') {
                    let os = this.openSpeed ? "1" : "0";
                    window.location.href = `https://ts.7li.cn/#/jxfios/1/397?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}&os=${os}`;
                    //window.location.href = `http://192.168.0.200:8081/#/sq/1/206?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}&os=${os}`;
                    return ;
                }

              if (this.gameId === '253') {
                  window.location.href = `https://jz.7li.cn/#/index?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}`;
                  //window.location.href = `http://192.168.0.21:8081/#/index?from=qiliApp&login=${this.login}&u=${this.u}&p=${this.p}`;
                  return ;
              }

                if (this.gameId === '107' || this.gameId === '407') {
                    this.$router.push({path: '/play/' + this.channelId + '/' + this.gameId + '?from=' + this.from});
                    return ;
                }

              if (!this.canPlay) {
                  Dialog.alert({
                      title : '温馨提示',
                      message : '手游请联系客服获取下载链接',
                      messageAlign: 'center'
                  })
                  return ;
              }
              this.$router.push({path: '/play/' + this.channelId + '/' + this.gameId + '?from=' + this.from});
            },
            getGameDetail() {
                let params = {
                    gameId: this.gameId,
                    channelId: this.channelId,
                    dyChannel: this.dyChannel,
                };
                gameDetail(params).then(res => {
                    if (res.data.success) {
                        let d = res.data.data;
                        this.gameName = d.gameName;
                        this.gameDesc = d.gameDesc;
                        this.gameImgUrl = d.gameImgUrl;
                        this.gameSize = d.gameSize;
                        this.tagList = d.tagList;
                        this.videoUrl = d.videoUrl;
                        this.videoImageUrl = d.videoImgUrl;
                        this.downLoadUrl = d.downLoadUrl;
                        this.canPlay = d.gameType === '1';
                        this.lastServerName = d.lastServerName;
                        this.lastOpenTime = d.lastOpenTime;
                        this.nextServerName = d.nextServerName;
                        this.nextOpenTime = d.nextOpenTime;
                        this.hasGift = d.hasGift;
                        if (this.android) {
                            this.showButton = d.androidCtrl === 'on';
                        }
                        else {
                            this.showButton = d.webviewCtrl === 'on';
                        }
                        //是否开启预约
                        if (this.gameId === '420' && this.from === 'qiliApp' &&  !this.downLoadUrl) {
                            this.showAppointmentView = true;
                            this.initAppointmentStatus();
                        }
                    }
                    else {
                        Toast.fail(res.data.error.message);
                    }
                    this.initFinished = true;
                })
            },
            goDetail(id,name) {
                uni.postMessage({
                    data: {
                        action: 'contentDetail',
                        msg: {id: id,name: name}
                    }
                });
            },
            goGiftDetail() {
                if (this.android) {
                    uni.postMessage({
                        data: {
                            action: 'giftDetail',
                            msg: this.gameId
                        }
                    });
                }
            },
            send() {
                if (this.input.length === 0) {
                    Toast('请输入游戏建议')
                    return ;
                }

                fankui(this.input).then(res => {
                    if (res.data.success) {
                        setTimeout(() => {
                            Toast('提交成功，感谢您的支持');
                        }, 1000)

                    }
                    else {
                        Toast.fail(res.data.error.message);
                    }
                })
            },
            initAppointmentStatus() {
                console.log(this.from, this.login);
                if (this.from === 'qiliApp' && this.login === '1') {
                    let options = {
                        url:"/app/getAppointmentStatus",
                        data: {
                            gameId: this.gameId,
                            username: this.u,
                            password: this.p
                        }
                    };
                    commRequest(options).then(res => {

                        if (res.data.success) {
                            this.appointmentStatus = res.data.data;
                        }
                    });
                }
            },
            appointmentGame() {
                if (this.login !== '1') {
                    Dialog.alert({
                        title : '温馨提示',
                        message : '请先在七里APP登录',
                        messageAlign: 'center'
                    })
                    return ;
                }
                let options = {
                    url:"/app/appointmentGame",
                    data: {
                        gameId: this.gameId,
                        username: this.u,
                        password: this.p
                    }
                };
                commRequest(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.appointmentStatus = '2';
                        Toast('游戏预约成功，请关注后续动态');
                    }
                    else {
                        Toast('游戏预约失败!');
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .main {
        width: 100%;
        height: 100vh;
        background-color: #F5F6F8;
        box-sizing: border-box;
        overflow-y: auto;
    }
    .title {
        background-color: #F5F6F8;
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding-left: 10px;
        font-size: 18px;
        position: fixed;
        z-index: 9999;
    }
    .game-info{
        background-color: #FFFFFF;
        height: 100px;
        border-radius: 5px;
        margin: 5px 15px;
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
    .game-size-view {
        font-size: 12px;
        color: #35973f;
        background: rgba(83, 239, 78, 0.19);
        padding: 3px 5px;
    }
    .game-text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        height: 80%;
        margin-left: 15px;
    }
    .no-feed {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        //text-indent: 28rpx;
    }
    .tags > span {
        padding: 3px 5px;
        margin-right: 5px;
        background-color: #FDF1EB;
        color: #EC7338;
        font-size: 13px;
    }
    .game-desc{
        background-color: #FFFFFF;
        border-radius: 5px;
        margin: 15px 15px;
        text-align: left;
        padding: 15px 10px;
        color: #333333;
        font-size: 13px;
    }
</style>